import React from 'react'
import "./Product.css"
import { useStateValue } from './StateProvider'

function Product({id,title, image, price, rating}) {

const [state, dispatch] = useStateValue()

const addToCart = () => {
    dispatch ({
        type:'ADD_TO_CART',
        item:{
            id: id,
            image: image,
            price: price,
            rating: rating,
        }
    })
}


return (
    <div>
        <div className="product">
            <div className="product_info">
                <p>{title}</p>
                <p className='product_price'>
                    <small>$</small>
                    <strong>{price}</strong>
                </p>
                <div className="product_rating">
                { Array(rating).fill().map((_, i) => ( <p>⭐</p> )) }
                </div>
            </div>

            <img src={image}
            alt="product_image" />

            <button onClick={addToCart}>Add to Cart</button>

        </div>
    </div>
)
}

export default Product 