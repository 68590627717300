import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



const firebaseConfig = {
    apiKey: "AIzaSyCtZoylwZLV6-zWAvSraPv-9WsCtJsLm0o",
    authDomain: "emarket-524ed.firebaseapp.com",
    projectId: "emarket-524ed",
    storageBucket: "emarket-524ed.appspot.com",
    messagingSenderId: "859112496677",
    appId: "1:859112496677:web:53da0bc62e2431cfeb69cc",
    measurementId: "G-GC1RR9KW8Y"
};



const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()

const auth = firebase.auth()

export {db, auth}