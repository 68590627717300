import './App.css';
import React from 'react';
import Header from './Header';
import Home from './Home';
import Checkout from './Checkout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';


function App() {
  return (
    <div className="App">
      <Router> 
        <Header/>

        <Routes>
        <Route path="/"element={ <Home/>} />
        <Route path="/login"element={ <Login/>} />
        <Route path="/checkout"element={ <Checkout/>} />         
        </Routes>

      </Router>
    </div>
  );
}

export default App;
