import React from 'react'
import "./CheckoutProduct.css"
import { useStateValue } from './StateProvider'

function CheckoutProduct({id, image, title, price, rating}) {

const [{basket}, dispatch] = useStateValue()

const removeFromBasket = () => {
    dispatch ({
        type: 'REMOVE_FROM_CART',
        id :id,
    })
}

return (
    <div>
        <div className="checkoutProduct">
            <img src={image}
            alt="" className="checkoutProduct_image" />

            <div className="checkoutProduct_info">
                <p className="checkoutProduct_title">{title}</p>
                <p className="checkoutProduct_price">
                    <small>$</small>
                    <strong>{price}</strong>
                </p>

                <div className="checkout_rating">
                { Array(rating).fill().map((_, i) => ( <p>⭐</p> )) }
                </div>
                <button onClick={removeFromBasket}>Remove from Cart</button>
            </div>

        </div>
    </div>
)
}

export default CheckoutProduct