import React from 'react';
import "./Header.css";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useParams, useNavigate } from "react-router-dom";
import { useStateValue } from './StateProvider';

function Header() {

    const [{basket}, dispatch] = useStateValue()


    return (
    <div className='header'>
        <Link to="/" style={{ textDecoration: 'none' }}>
            <div className='header_logo'>
                <StoreIcon className='header_logoImage' fontSize='large'/>
                <h2 className='header_logoTitle'>E-ASIMA</h2>
            </div>
        </Link>
        

        <div className="header__search">
            <input type="text" className='header_search'/>
            <SearchIcon className='header_searchIcon'/>
        </div>

        <div className="header__nav">
            <Link to="/login" style={{ textDecoration: 'none' }}>
                <div className="nav__item">
                <span className="nav__itemLineOne">Hello Shopper</span>
                <span className="nav__itemLineTwo">Sign In</span>
            </div>    
            </Link>    
            <div className="nav__item">
                <span className="nav__itemLineOne">Your</span>
                <span className="nav__itemLineTwo">Shop</span>
            </div>
            <Link to="/checkout" style={{ textDecoration: 'none' }} >
                <div className='itemBasket'>
                    <ShoppingCartIcon />
                    <span className="nav__itemLineTwo nav_count">{basket.length}</span>
                </div>
            </Link>
            
        </div>

    </div>
    )
}

export default Header