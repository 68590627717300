import React, {useState} from "react";
import './Login.css'
import {Link, useNavigate} from 'react-router-dom'
import StoreIcon from '@mui/icons-material/Store';
import {auth} from './firebase'
import { createUserWithEmailAndPassword } from "firebase/auth"
import { signInWithEmailAndPassword } from "firebase/auth"


function Login () {


        const navigate = useNavigate()
        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')


        const signIn = (e) => {
            e.preventDefault();
    
            signInWithEmailAndPassword(auth, email, password).then((auth) => {
                if (auth) {
                navigate('/')
                }
            })
            .catch(error => alert(error.message))
        }



        const register = (e) => {
            e.preventDefault();
    
            createUserWithEmailAndPassword(auth, email, password).then((auth) => {
                if (auth) {
                navigate('/')
                }
            })
            .catch(error => alert(error.message))
        }


        return (
            <div className="login">
                <Link to='/' style={{textDecoration: 'none'}}>
                    <div className="login_logo">
                        <StoreIcon className='login_logoImage' fontSize='large'/>
                        <h2 className="login_logoTitle">E-ASIMA</h2>
                    </div>
                </Link>

                <div className="login_container">
                    <h1>Sign-in </h1>

                    <form >

                        <h5>E-mail</h5>
                        <input type="text" value={email} onChange={e => setEmail(e.target.value)} />

                        <h5>Password</h5>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />

                        <button type="submit" className="login_signInButton" onClick={signIn}> Sign In</button>
                    </form>

                    <p>
                        By signing in you agree to the EAsima Website Terms and Conditions
                    </p>

                    <button className="login_registerButton" onClick={register}> Create your Account</button>
                </div>
            </div>

        )

}

export default Login