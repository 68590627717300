import React from 'react'
import "./Home.css"
import Product from "./Product"

const Home = () => {
return (
    
        <div className="home">
            <div className="home_container">
                <img src="https://www.statcan.gc.ca/o1/sites/default/files/2021-11/shopping_2.jpg" 
                alt="" className="home__image" />

                <div className="home_row">
                    <Product
                    id="1"
                    title="Ipad"
                    price={500}
                    rating={5}
                    image="https://www.apple.com/newsroom/images/product/ipad/standard/apple_new-ipad-air_new-design_09152020.jpg.landing-big_2x.jpg"
                    />
                    <Product
                    id="2"
                    title="Airpod Max"
                    price={780}
                    rating={4}
                    image="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/airpods-max-select-spacegray-202011_FV1_FMT_WHH?wid=940&hei=800&fmt=jpeg&qlt=90&.v=1604776025000"
                    />
                    <Product
                    id="3"
                    title="Apple Watch"
                    price={279}
                    rating={4}
                    image="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/MPH43ref_VW_34FR+watch-45-alum-midnight-nc-8s_VW_34FR_WF_CO+watch-face-45-nike7s-desertberry-spruceaura_VW_34FR_WF_CO?wid=1400&hei=1400&trim=1%2C0&fmt=p-jpg&qlt=95&.v=1660757976267%2C1661968921009%2C1662143432353"
                    />
                    
                </div>

                <div className="home_row">
                    <Product
                    id="12"
                    title="yeezy 7"
                    price={1500}
                    rating={5}
                    image="https://m.media-amazon.com/images/I/61yhHXEXjIL._AC_UF1000,1000_QL80_.jpg"
                    />
                    <Product
                    id="13"
                    title="Amazon Echo Smart Speakers, with Alexa"
                    price={195}
                    rating={4}
                    image="https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6347/6347261cv11d.jpg"
                    />

                    <Product
                    id="14"
                    title="Ipad"
                    price={500}
                    rating={4}
                    image="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/ipad-10th-gen-finish-select-202212-yellow-wifi_AV1_FMT_WHH?wid=1280&hei=720&fmt=p-jpg&qlt=95&.v=1670856352013"
                    />
                </div>

                <div className="home_row">
                    <Product
                    id="15"
                    title="Steve Sunglasses in Black On Transparent and Grey | Ray-Ban"
                    price={125}
                    rating={4}
                    image="https://images.ray-ban.com/is/image/RayBan/8056597717823_0005.png?impolicy=SEO_1x1"
                    />
                    <Product
                    id="16"
                    title="Iphone 14 pro Max"
                    price={1099}
                    rating={4}
                    image="https://www.technogear.bg/storage/products/0006303942732/0006303945603.jpeg"
                    />

                    <Product
                    id="17"
                    title="Iphone 14 Pro"
                    price={999}
                    rating={4}
                    image="https://www.notebookcheck.net/uploads/tx_nbc2/Bild_Apple_iPhone_14_Pro-3956.jpg"
                    />
                </div>
            </div>
        </div>
    
)
}

export default Home